import AssetIcon from "../assets/images/sidebar/AssetIcon.svg";
import ProfileIcon from "../assets/images/sidebar/ProfileIcon.svg";
//import feedBackIcon from "../../assets/images/sidebar/feedBackIcon.svg";

import HomeIcon from "../assets/images/sidebar/HomeIcon.svg";
import ReleaseInfoIcon from "../assets/images/sidebar/ReleaseInfoIcon.svg";

import UsersIcon from "../assets/images/adminPanel/UsersIcon.svg";
import DiscoveryIcon from "../assets/images/icons/discovery-icon.svg";

import DetailsSettingsIcon from "../assets/images/adminPanel/DetailsSettingsIcon.svg";
import RiskFactorsIcon from "../assets/images/adminPanel/RiskFactorsIcon.svg";
import BusinessUnitsIcon from "../assets/images/adminPanel/BusinessUnits.svg";
import RegionIcon from "../assets/images/adminPanel/adminRegionIcon.svg";
import AssignBUIcon from "../assets/images/adminPanel/AssignBUIcon.svg";
import CardTemplateIcon from "../assets/images/adminPanel/cardTemplate.svg";
import RoleManageIcon from "../assets/images/sidebar/RoleManagementIcon.svg";
import PolicyIcon from "../assets/images/adminPanel/Policy.svg";
import OrgManagementIcon from "../assets/images/adminPanel/org_management.svg";

import SectionIcon from "../assets/images/adminPanel/Section.svg";
import SubsectionIcon from "../assets/images/adminPanel/Sub-Section.svg";
import AttributeIcon from "../assets/images/adminPanel/attribute.svg";

import { routeUrl } from "../constants/routeUrlConstants";
import { PolicyNameConst } from "./policyConstants";

export const sidebarDataConfig = (
  searchText,
  regionId,
  businessId,
  appId,
  nodeType,
  nodeId,
  nlpQuery
) => {
  const dataLink = [
    {
      label: "Home",
      state: false,
      url: `${routeUrl("home", "sidebar")}`,
      icon: HomeIcon,
    },

    {
      label: "Assets",
      state: true,
      url: `${routeUrl("asset", "sidebar", searchText)}`,
      icon: AssetIcon,
    },
    {
      label: "Profile",
      state: true,
      url: `${routeUrl("profile", "sidebar", searchText)}`,
      icon: ProfileIcon,
    },
    {
      label: "Discovery",
      state: true,
      url: `${routeUrl("discovery", "sidebar")}`,
      icon: DiscoveryIcon,
    },
    {
      label: "Release Info",
      state: false,
      url: `${routeUrl("whatsNew", "sidebar")}`,
      icon: ReleaseInfoIcon,
    },
  ];

  return dataLink;
};

export const adminSidebarDataConfig = () => {
  const dataLink = [
    {
      label: "Users",
      state: false,
      url: `${routeUrl("adminUsers", "sidebar")}`,
      icon: UsersIcon,
    },
    {
      label: "Regions",
      state: false,
      url: `${routeUrl("adminRegions", "sidebar")}`,
      icon: RegionIcon,
    },
    {
      label: "Business Units",
      state: false,
      url: `${routeUrl("adminBusinessunit", "sidebar")}`,
      icon: BusinessUnitsIcon,
    },
    {
      label: PolicyNameConst.policies,
      state: false,
      url: `${routeUrl("adminPolicy", "sidebar")}`,
      icon: PolicyIcon,
    },
    {
      label: "Details Settings",
      state: false,
      url: `${routeUrl("adminDetails", "sidebar")}`,
      icon: DetailsSettingsIcon,
    },
    {
      label: "Risk Factors",
      state: false,
      url: `${routeUrl("adminRiskFactors", "sidebar")}`,
      icon: RiskFactorsIcon,
    },
    {
      label: "Business Unit Assignment",
      state: false,
      url: `${routeUrl("adminNodeBuRel", "sidebar")}`,
      icon: AssignBUIcon,
    },
    {
      label: "Card Templates",
      state: false,
      url: `${routeUrl("adminCardTemplate", "sidebar")}`,
      icon: CardTemplateIcon,
    },
    {
      label: "Role Management",
      state: false,
      url: `${routeUrl("adminRoleMang", "sidebar")}`,
      icon: RoleManageIcon,
    },
    {
      label: "Organization Management",
      state: false,
      url: `${routeUrl("adminOrgMang", "sidebar")}`,
      icon: OrgManagementIcon,
    },
    /**Temporary commented sections and subsections for the release rc 7.0  */
    // {
    //   label: "Section",
    //   state: false,
    //   url: `${routeUrl("adminSection", "sidebar")}`,
    //   icon: SectionIcon,
    // },
    // {
    //   label: "Subsection",
    //   state: false,
    //   url: `${routeUrl("adminSubsection", "sidebar")}`,
    //   icon: SubsectionIcon,
    // },
    {
      label: "Extended attributes",
      state: false,
      url: `${routeUrl("adminAttributes", "sidebar")}`,
      icon: AttributeIcon,
    },
  ];

  return dataLink;
};
