import React, { useEffect, useState } from "react";
import {
  accessConstants,
  moduleConstants,
  subModuleConstants,
} from "../../constants/accessManagementConstants";
import { accessDataByRoleAtom } from "../../atom/jotai";
import AdminUsetlistSearch from "../../components/AdminUsetlistSearch/AdminUsetlistSearch";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import AdminAttributeTable from "../../components/AdminAttribute/AdminAttributeTable";
import { AssignBUDataTypes } from "../../constants/adminConfigConstant";
import ApplicationIcon from "../../assets/images/adminPanel/Application.svg";
import ModelIcon from "../../assets/images/listview/model-chip.svg";
import DatasetIcon from "../../assets/images/adminPanel/Dataset.svg";
import NoResult from "../../components/NoResult/NoResult";
import { useAtom } from "jotai";
import { isAccessProvided } from "../../services/assetManagementService";
import AdminAttributeCrudDialog from "../../components/AdminAttribute/AdminAttributeCrudDialog";
import { useGetEntityAttributedata } from "../../api/readApiFunctions";
import { CircularProgress } from "@mui/material";

/**
 * Component : AdminAttributePage
 *
 * Description :This is the main page of admin side attribute management. In admin side we are creatong/deleteing the attribute.
 * Here we are not updating the value of attribute.This componet is responsible for displaying the layout and the common fields
 * like heading, create attribute button, search box.
 *  */

const AdminAttributePage = () => {
  // get all attributeData as per entity wise
  const {
    data: getAttributeData,
    isLoading: getAttributeDataLoading,
    refetch: getAttributeDataRefetch,
  } = useGetEntityAttributedata();

  //state for managing access management system golbal value
  const [accessDataByRole] = useAtom(accessDataByRoleAtom);

  const [selectedType, setSelectedType] = useState(AssignBUDataTypes.apps);
  const [attributeData, setAttributeData] = useState([]); //state to maintain all attribute list coming from database
  const [lengthOfEntitiesAttr, setLengthOfEntitiesAttr] = useState({
    app: 0,
    model: 0,
    input_data_table: 0,
  }); //state to maintain all attribute list coming from database
  const [openAttributeDialog, setOpenAttributeDialog] = useState(false); // to show a create new attribute dialog

  const [serachedVal, setSearchedVal] = useState("");

  useEffect(() => {
    if (getAttributeData) {
      let attrLenghtObj = {};
      getAttributeData?.length > 0 &&
        getAttributeData?.forEach((entityAttrData) => {
          if (serachedVal === "") {
            if (selectedType === entityAttrData.asset_type) {
              setAttributeData(entityAttrData.extended_attrs);
            }

            attrLenghtObj[entityAttrData?.asset_type] =
              entityAttrData?.extended_attrs?.length;
          } else {
            getAttributeData?.length > 0 &&
              getAttributeData?.forEach((entityAttrData) => {
                const filteredRows = entityAttrData?.extended_attrs?.filter(
                  (row) => {
                    return row.name
                      .toLowerCase()
                      .includes(serachedVal.toLowerCase());
                  }
                );
                attrLenghtObj[entityAttrData?.asset_type] = filteredRows.length;
                if (selectedType === entityAttrData?.asset_type) {
                  setAttributeData(filteredRows);
                }
              });
          }
        });
      setLengthOfEntitiesAttr(attrLenghtObj);
    }
  }, [getAttributeData, selectedType, accessDataByRole, serachedVal]);

  //Below useEffect is used for giving title to current page
  useEffect(() => {
    document.title = "Admin Attribute Page";
  });

  const handleAddAttribute = () => {
    setOpenAttributeDialog(true);
  };

  return (
    <>
      <div className="userList-heading1" id="adminAttributeHeading">
        Extended Attributes
      </div>
      {isAccessProvided(
        moduleConstants.admin_view,
        subModuleConstants.extended_attribute,
        null,
        accessConstants.read,
        accessDataByRole
      ) === true ? (
        <div className="userlist" id="adminAttribute">
          <div
            className="userlist-search-and-create paddingTRL30px"
            id="adminAttributeSearch"
          >
            <AdminUsetlistSearch
              getAttributeData={getAttributeData}
              setAttributeData={setAttributeData}
              selectedEntityType={selectedType}
              isAttribute={true}
              setLengthOfEntitiesAttr={setLengthOfEntitiesAttr}
              setSearchedVal={setSearchedVal}
            />
            {isAccessProvided(
              moduleConstants.admin_view,
              subModuleConstants.extended_attribute,
              null,
              accessConstants.write,
              accessDataByRole
            ) === true && (
              <ButtonComponent
                name={"Create Attribute"}
                activeButton={true}
                handleActiveClick={handleAddAttribute}
              />
            )}
          </div>

          <div
            className="filter-container"
            style={{ margin: "25px 15px 0px 15px" }}
          >
            <div
              className={`chip-button ${
                selectedType === AssignBUDataTypes.apps && "chip-button-active"
              }`}
              onClick={() => {
                setSelectedType(AssignBUDataTypes.apps);
              }}
            >
              <img src={ApplicationIcon} alt="" className="filter-image" />
              <h3 className="filter-name">Applications</h3>
              <p>({lengthOfEntitiesAttr.app})</p>
            </div>

            <div
              className={`chip-button ${
                selectedType === AssignBUDataTypes.models &&
                "chip-button-active"
              }`}
              onClick={() => {
                setSelectedType(AssignBUDataTypes.models);
              }}
            >
              <img src={ModelIcon} alt="" className="filter-image" />
              <h3 className="filter-name">Models</h3>
              <p>({lengthOfEntitiesAttr.model})</p>
            </div>

            <div
              className={`chip-button ${
                selectedType === AssignBUDataTypes.input_data_tables &&
                "chip-button-active"
              }`}
              onClick={() => {
                setSelectedType(AssignBUDataTypes.input_data_tables);
              }}
            >
              <img src={DatasetIcon} alt="" className="filter-image" />
              <h3 className="filter-name">Datasets</h3>
              <p>({lengthOfEntitiesAttr.input_data_table})</p>
            </div>
          </div>

          {getAttributeDataLoading ? (
            <CircularProgress />
          ) : attributeData && attributeData?.length === 0 ? (
            <div
              className="hgt userlist-users-table"
              id="adminUserlistNoResult"
            >
              <NoResult title={"No Data Available"} />
            </div>
          ) : (
            <div
              className="userlist-users-table marginT0 borderR"
              id="adminAttributeTableDiv"
            >
              <AdminAttributeTable
                attributeData={attributeData}
                accessDataByRole={accessDataByRole}
                selectedType={selectedType}
                getAttributeDataRefetch={getAttributeDataRefetch}
              />
            </div>
          )}

          {openAttributeDialog && (
            <AdminAttributeCrudDialog
              openAttributeDialog={openAttributeDialog}
              setOpenAttributeDialog={setOpenAttributeDialog}
              invokerType="create"
              getAttributeDataRefetch={getAttributeDataRefetch}
            />
          )}
        </div>
      ) : (
        <div className="hgt userlist-users-table" id="noAccessToRoleManagment">
          {accessDataByRole && <NoResult title={"No Access to Attributes"} />}
        </div>
      )}
    </>
  );
};

export default AdminAttributePage;
