import { Box, IconButton, Modal } from "@mui/material";
import React, { useState, useEffect } from "react";
import { capitalize } from "lodash";
import { useAtom } from "jotai";
import EditableTableComp from "../MUITable/EditableTableComp";
import { Close } from "@mui/icons-material";
import EditIcon from "../../assets/images/commonIcons/Edit_New.svg";
import EditDisabledIcon from "../../assets/images/commonIcons/Disabled_Edit_New.svg";
import { accessDataByRoleAtom } from "../../atom/jotai";
import { isAccessProvided } from "../../services/assetManagementService";
import {
  accessConstants,
  moduleConstants,
  subModuleConstants,
} from "../../constants/accessManagementConstants";
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import { useUpdateAttributesValues } from "../../api/updateApiFunctions";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  // height: "48%",
  bgcolor: "#FFFFFF",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
  padding: "20px 20px 45px 20px",
};

const includingFieldList = ["name", "description", "value"];

/**
 * Component : DetailListModal
 *
 * Description :This component is responsible for showing the modal which is called the editable table to display.
 * And also responsible for creating the data for table. And also we can update the attribute value.
 *
 * Props : extnsblAttrdata - data which is required,
 *         heading- Modal heading,
 *         openTableModal, setOpenTableModal - modal setter and getter state,
 *         entity_id ,asset_type- For which entity the modal is opened,
 *  */

const DetailListModal = ({
  extnsblAttrdata,
  heading,
  openTableModal,
  setOpenTableModal,
  asset_type,
  entity_id,
}) => {
  //Global states
  const [accessDataByRole] = useAtom(accessDataByRoleAtom);

  //Local states
  const [tableHeader, setTableHeader] = useState([]);
  const [tableRowData, setTableRowData] = useState([]);
  const [editColumn, setEditColumn] = useState(false);
  const [attrDataAftrUpdate, setAttrDataAftrUpdate] = useState([]);

  const {
    mutate: updateAttributesValues,
    data: dataAfterUpdateAttrValues,
    isSuccess: isSuccessAfterUpdateAttrValues,
    isError: isErrorAfterUpdateAttrValues,
    error: errorAfterUpdateAttrValues,
  } = useUpdateAttributesValues();

  //Below uesEffect() is used for when the attribute is updated attribute value successfully
  useEffect(() => {
    if (isSuccessAfterUpdateAttrValues) {
      toast.success(dataAfterUpdateAttrValues?.message);
      setAttrDataAftrUpdate(dataAfterUpdateAttrValues?.data?.extended_attrs);
      setTableRowData(dataAfterUpdateAttrValues?.data?.extended_attrs);
      setEditColumn(false);
    }
  }, [dataAfterUpdateAttrValues, isSuccessAfterUpdateAttrValues]);

  //Below uesEffect() is used for showing an error while hitting the update attribute value api
  useEffect(() => {
    if (isErrorAfterUpdateAttrValues) {
      toast.error(errorAfterUpdateAttrValues?.response?.data?.error[0]);
      setEditColumn(false);
      setAttrDataAftrUpdate(extnsblAttrdata); //save original attributes info
    }
  }, [errorAfterUpdateAttrValues]);

  //Below userEffect is used to create data for table
  useEffect(() => {
    if (extnsblAttrdata) {
      let col_data = [];
      let col_data_row_wise = [];
      let row_data = [];
      extnsblAttrdata.length > 0 &&
        extnsblAttrdata.forEach((attrData) => {
          let row_data_object = {};

          Object.entries(attrData).forEach(([key, value], i) => {
            let col_data_object = {};
            if (includingFieldList.includes(key)) {
              col_data_object = { field: key, label: capitalize(key) };

              if (key === "value") {
                col_data_object.width = "30%";
              } else if (key === "name") {
                col_data_object.width = "30%";
              } else if (key === "description") {
                col_data_object.width = "40%";
              }

              //Adding column name as per row wise
              col_data_row_wise.push(col_data_object);
            }

            //adding row value as per column and also the other values like id, etc.,
            row_data_object[key] = value;
          });

          col_data.push(col_data_row_wise);
          col_data_row_wise = []; //making array blank to avoid duplication
          row_data.push(row_data_object); //clubbing single row to the whole bunch of data
        });

      if (col_data.length > 0) {
        setTableHeader(col_data[0]);
      }
      if (row_data.length > 0) {
        setTableRowData(row_data);
      }
      setAttrDataAftrUpdate(JSON.parse(JSON.stringify(extnsblAttrdata))); //Add Initial value for updation
    }
  }, [extnsblAttrdata]);

  const handleChangeOfColumn = (id, val) => {
    const updatedAtrributeData = attrDataAftrUpdate.map((attrData) => {
      if (attrData.id === id) {
        attrData.value = val;
      }
      return attrData;
    });

    setAttrDataAftrUpdate(updatedAtrributeData);
  };

  const handleUpdateAttribute = () => {
    const payload = {
      asset_type: asset_type,
      entity_id: entity_id,
      modInvoker: "details",
      extended_attrs: attrDataAftrUpdate,
    };
    updateAttributesValues(payload);
  };

  const handleCancel = () => {
    setEditColumn(false);
    setAttrDataAftrUpdate(JSON.parse(JSON.stringify(extnsblAttrdata))); //Add Initial value for updation
  };

  return (
    <Modal
      open={openTableModal}
      onClose={() => {
        setOpenTableModal(false);
      }}
    >
      <Box sx={style}>
        <div
          style={{
            marginTop: "-25px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <div id="modalHeader">
            <h2>{heading}</h2>
          </div>
          <div style={{ display: "flex" }}>
            {accessDataByRole &&
              isAccessProvided(
                moduleConstants.details,
                subModuleConstants.crud,
                null,
                accessConstants.write,
                accessDataByRole
              ) && (
                <IconButton
                  id="modalEditButton"
                  sx={{
                    "&.MuiIconButton-root": {
                      padding: "0px 4px",
                      marginTop: "7px",
                      marginRight: "12px",
                      border: " 0.25px solid #6d8291",
                      borderRadius: "4px",
                      boxShadow: "0px 0px 4px rgb(0 0 0 / 25%)",
                      color: "#000",
                    },
                  }}
                  onClick={() => {
                    setEditColumn(true);
                  }}
                  disabled={editColumn}
                >
                  <img
                    src={editColumn ? EditDisabledIcon : EditIcon}
                    alt="eidt"
                    width={"17px"}
                  />
                </IconButton>
              )}

            <IconButton
              id="modalCloseButton"
              sx={{
                "&.MuiIconButton-root": {
                  padding: "0px",
                  marginTop: "7px",
                  marginRight: "12px",
                  border: " 0.25px solid #6d8291",
                  borderRadius: "4px",
                  boxShadow: "0px 0px 4px rgb(0 0 0 / 25%)",
                  color: "#000",
                },
              }}
              // sx={{ color: "#000" }}
              onClick={() => {
                setOpenTableModal(false);
                setAttrDataAftrUpdate([]);
                setTableHeader([]);
                setTableRowData([]);
              }}
            >
              <Close
                sx={{
                  "&.MuiIconButton-root": { width: "22px" },
                }}
              />
            </IconButton>
          </div>
        </div>

        <Box
          id="loadingBox"
          sx={{
            position: "relative",
          }}
        >
          <>
            <div style={{ marginBottom: "10px" }}>
              <EditableTableComp
                tableHeader={tableHeader && tableHeader}
                tableRowData={tableRowData && tableRowData}
                editColumn={editColumn}
                attrDataAftrUpdate={attrDataAftrUpdate}
                handleChangeOfColumn={handleChangeOfColumn}
              />
            </div>

            {editColumn && (
              <div style={{ position: "absolute", right: 0 }}>
                <ButtonComponent
                  name={"Update"}
                  activeButton={true}
                  handleActiveClick={handleUpdateAttribute}
                />
                <ButtonComponent
                  name={"Cancel"}
                  passiveButton={true}
                  handlePassiveClick={handleCancel}
                />
              </div>
            )}
          </>
        </Box>
      </Box>
    </Modal>
  );
};

export default DetailListModal;
