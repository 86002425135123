import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Tooltip,
  tooltipClasses,
  styled,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
} from "@mui/material";
import "./MUITable.css";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "rgba(0, 0, 0, 0.80)",
    color: "#ffffff",
    border: " 0.5px solid #6D8291",
    boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
    borderRadius: "4px",
    fontSize: 14,
    fontFamily: "Lato",
    fontWeight: 400,
  },
}));

/**
 * Component : EditableTableComp
 *
 * Description :This component is responsbel for displaying the editable table
 *
 * Props : tableHeader - table column object,
 *         tableRowData-table row data,
 *         editColumn -state to show whether column is editable or not,
 *         attrDataAftrUpdate- state which displays the data with edited new value,
 *         handleChangeOfColumn - handler function for saving the editable value,
 *  */

const EditableTableComp = ({
  tableHeader,
  tableRowData,
  editColumn,
  attrDataAftrUpdate,
  handleChangeOfColumn,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const showValue = (id, type) => {
    let val = null;
    attrDataAftrUpdate.length > 0 &&
      attrDataAftrUpdate.map((attrData) => {
        if (attrData.id === id) {
          val = attrData.value;
        }
      });
    return val;
  };

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow style={{ background: "#DDE6EA" }}>
                {tableHeader.map((columnInfo) => (
                  <TableCell
                    key={columnInfo.field}
                    //   align={columnInfo.align}
                    style={{
                      width: columnInfo.width,
                      backgroundColor: "#dde6ea",
                    }}
                  >
                    {columnInfo.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableRowData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((rowData) => {
                  return (
                    <TableRow
                      key={rowData.id}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      {tableHeader.map((column) => {
                        const value = rowData[column?.field];

                        if (column?.field === "value" && editColumn) {
                          if (rowData.type === "boolean") {
                            return (
                              <TableCell
                                align="left"
                                component="th"
                                scope="row"
                                sx={{ padding: "4px 0px 4px 10px" }}
                              >
                                <RadioGroup
                                  id={`TypeForAttr${rowData.id}`}
                                  row
                                  aria-labelledby="value-update-row-radio-buttons-group-label"
                                  name="value-update-row-radio-buttons-group"
                                  defaultValue={showValue(
                                    rowData.id,
                                    rowData.type
                                  )}
                                  onChange={(e) => {
                                    handleChangeOfColumn(
                                      rowData.id,
                                      e.target.value
                                    );
                                  }}
                                  onClick={(e) => {
                                    handleChangeOfColumn(
                                      rowData.id,
                                      e.target.value
                                    );
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "100%",
                                      // display: "flex",
                                      // justifyContent: "space-around",

                                      display: "grid",
                                      gridTemplateColumns: "100px 100px",
                                    }}
                                  >
                                    <div style={{ display: "flex" }}>
                                      <FormControlLabel
                                        value={"true"}
                                        control={<Radio color="default" />}
                                        label="Yes"
                                      />
                                    </div>
                                    <div style={{ display: "flex" }}>
                                      <FormControlLabel
                                        value={"false"}
                                        control={<Radio color="default" />}
                                        label="No"
                                      />
                                    </div>
                                  </div>
                                </RadioGroup>
                              </TableCell>
                            );
                          } else if (rowData.type === "string") {
                            return (
                              <TableCell
                                align="left"
                                component="th"
                                scope="row"
                                sx={{ padding: "6px 0px 4px 10px" }}
                              >
                                <TextField
                                  label="Enter text"
                                  sx={{
                                    "& .MuiInputBase-root": {
                                      padding: "1px",
                                      //   maxHeight: "30px",
                                    },
                                    "& .MuiInputBase-input": {
                                      fontFamily:
                                        "var(--fontFamilyText)!important",
                                      fontWeight: 400,
                                      fontSize: "16px",
                                      color: "#000000",
                                    },
                                  }}
                                  fullWidth
                                  multiline
                                  rows={2}
                                  //   InputProps={{ disableUnderline: true }}
                                  defaultValue={showValue(
                                    rowData.id,
                                    rowData.type
                                  )}
                                  onChange={(e) => {
                                    handleChangeOfColumn(
                                      rowData.id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </TableCell>
                            );
                          } else if (rowData.type === "number") {
                            return (
                              <TableCell
                                align="left"
                                component="th"
                                scope="row"
                                sx={{ padding: "4px 0px 4px 10px" }}
                              >
                                <TextField
                                  label="Enter a number"
                                  type="number"
                                  fullWidth
                                  sx={{
                                    "& .MuiInputBase-root": {
                                      padding: "1px",
                                      maxHeight: "40px",
                                    },
                                    "& .MuiInputBase-input": {
                                      fontFamily:
                                        "var(--fontFamilyText)!important",
                                      fontWeight: 400,
                                      fontSize: "16px",
                                      color: "#000000",
                                    },
                                  }}
                                  defaultValue={showValue(
                                    rowData.id,
                                    rowData.type
                                  )}
                                  onChange={(e) => {
                                    handleChangeOfColumn(
                                      rowData.id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </TableCell>
                            );
                          }
                        } else {
                          return (
                            <TableCell
                              sx={{
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                              }}
                              align="left"
                              component="th"
                              scope="row"
                            >
                              {value === "" ||
                              value === null ||
                              value === "-" ? (
                                "-"
                              ) : (
                                <LightTooltip title={value} followCursor>
                                  <span>
                                    {rowData.type === "boolean" &&
                                    column?.field === "value"
                                      ? value === "true"
                                        ? "Yes"
                                        : "No"
                                      : value}
                                  </span>
                                </LightTooltip>
                              )}
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5]}
          component="div"
          count={tableRowData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};

export default EditableTableComp;
