export const routeUrl = (
  urlVal,
  invoker,
  searchText,
  regionId,
  businessId,
  appId,
  nodeType,
  nodeId,
  nlpQuery
) => {
  const url = {
    login: `/login`,
    confirmEmail: `/confirm-email`,
    resetPassword: `/reset-password`,
    home: `/`,
    asset:
      invoker === "app"
        ? "/result/listview/:searchText"
        : `/result/listview/${searchText}`,
    assetGraphview:
      invoker === "app"
        ? "/result/graphview/:searchText"
        : `/result/graphview/${searchText}`,
    profile:
      invoker === "app"
        ? "/compliance-result/compliance-listview/:searchText"
        : `/compliance-result/compliance-listview/konfer-confidence`,
    profileSummary:
      invoker === "app"
        ? "/compliance-result/compliance-summary/:searchText/:regionId/:businessId/:appId"
        : `/compliance-result/compliance-summary/${searchText}/${regionId}/${businessId}/${appId}`,
    pageNlq:
      invoker === "app"
        ? "/nlp-query-result/:nlpQuery"
        : `/nlp-query-result/:nlpQuery`,
    observability:
      invoker === "app"
        ? "/observability/:nodeType/:nodeId"
        : `/observability/${nodeType}/${nodeId}`,
    detail:
      invoker === "app"
        ? "/detail/:nodeType/:nodeId"
        : `/detail/${nodeType}/${nodeId}`,
    whatsNew: `/whats-new`,
    adminUsers: `/admin/userlist`,
    adminDetails: `/admin/details`,
    adminRegions: `/admin/regions`,
    adminBusinessunit: `/admin/businessunit`,
    adminPolicy: `/admin/policy`,
    adminNodeBuRel: `/admin/node-businessunit-relationship`,
    adminCardTemplate: `/admin/cardTemplate`,
    adminRoleMang: `/admin/roleManagement`,
    adminRiskFactors: `/admin/riskfactors`,
    adminOrgMang: `/admin/organization-management`,
    adminSection: `/admin/section`,
    adminSubsection: `/admin/subsection`,
    adminAttributes: `/admin/extensible-attribute`,
    amiDeployment: `/ami-deployment`,
    discovery: `/discovery`,
    page403: `/403-error`,
    page404: `/404-error`,
  };
  return url[urlVal];
};
