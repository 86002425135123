import React, { useEffect, useState } from 'react'
import { useGetAllSubsection } from '../../api/readApiFunctions'
import AdminUsetlistSearch from '../../components/AdminUsetlistSearch/AdminUsetlistSearch'
import NoResult from '../../components/NoResult/NoResult'
import AdminSubsectionTable from '../../components/AdminSubsection/AdminSubsectionTable'
import AdminSubsectionForm from '../../components/AdminSubsection/AdminSubsectionForm'

const SubsectionAdminPage = () => {
    const {data: allSubsectionsData , isLoading , refetch} = useGetAllSubsection()

    const [subsectionData , setSubsectionData] = useState([])
    const [isSubsectionFormOpen , setIsSubsectionFormOpen] = useState(false)
    const [formAction , setFormAction] = useState("create")

    useEffect(() => {
        if (allSubsectionsData) {
            setSubsectionData(allSubsectionsData)
        }
      }, [allSubsectionsData])  

  return (
    <>
    <div className="userList-heading1" id="businessunitHeading">
      Subsection
    </div>

    <div className="userlist" id="businessunit">
          <div
            className="userlist-search-and-create paddingTRL30px"
            id="businessunitSearch"
          >
            <AdminUsetlistSearch
              allSectionsData={allSubsectionsData}
              sectionData={subsectionData}
              setSectionData={setSubsectionData}
              isSectionData={true}
            />

              <button
                id="createBusinessunit"
                className="userlist-createNewUser-button"
                onClick={() => {
                    setIsSubsectionFormOpen(true);
                  setFormAction("create")
                }}
              >
                New Subsection
              </button>
            
          </div>
          {subsectionData && subsectionData?.length === 0 && isLoading === false ? (
            <div className="hgt userlist-users-table" id="businessunitNoResult">
              <NoResult title={"No Data Available"} />
            </div>
          ) : (
            <div className="userlist-users-table" id="businessunitTable">
              <AdminSubsectionTable
                subSectionData={subsectionData}
                isSubsectionFormOpen={isSubsectionFormOpen}
                setIsSubsectionFormOpen={setIsSubsectionFormOpen}
                setFormAction={setFormAction}
                formAction={formAction}
                refetch={refetch}
              />
            </div>
          )}
        </div>

        {isSubsectionFormOpen && formAction === "create" && (
        <AdminSubsectionForm
        isSubsectionFormOpen={isSubsectionFormOpen}
          setIsSubsectionFormOpen={setIsSubsectionFormOpen}
          formAction={formAction}
          refetch={refetch}
        />
      )}
    </>
  )
}

export default SubsectionAdminPage