import React, { useState } from 'react'
import { useGetAllSection } from '../../api/readApiFunctions'
import { useEffect } from 'react'
import AdminUsetlistSearch from '../../components/AdminUsetlistSearch/AdminUsetlistSearch'
import NoResult from '../../components/NoResult/NoResult'
import AdminSectionTable from '../../components/AdminSection/AdminSectionTable'
import AdminSectionForm from '../../components/AdminSection/AdminSectionForm'

const SectionAdminPage = () => {
  const {data: allSectionsData , isLoading , refetch} = useGetAllSection()

  const [sectionData , setSectionData] = useState([])
  const [isSectionFormOpen , setIsSectionFormOpen] = useState(false)
  const [formAction , setFormAction] = useState("create")

  useEffect(() => {
    if (allSectionsData) {
      setSectionData(allSectionsData)
    }
  }, [allSectionsData])  
  
  return (
    <>
    <div className="userList-heading1" id="businessunitHeading">
      Section
    </div>

    <div className="userlist" id="businessunit">
          <div
            className="userlist-search-and-create paddingTRL30px"
            id="businessunitSearch"
          >
            <AdminUsetlistSearch
              allSectionsData={allSectionsData}
              sectionData={sectionData}
              setSectionData={setSectionData}
              isSectionData={true}
            />

              <button
                id="createBusinessunit"
                className="userlist-createNewUser-button"
                onClick={() => {
                  setIsSectionFormOpen(true);
                  setFormAction("create")
                }}
              >
                New Section
              </button>
            
          </div>
          {sectionData && sectionData?.length === 0 && isLoading === false ? (
            <div className="hgt userlist-users-table" id="businessunitNoResult">
              <NoResult title={"No Data Available"} />
            </div>
          ) : (
            <div className="userlist-users-table" id="businessunitTable">
              <AdminSectionTable
                sectionData={sectionData}
                isSectionFormOpen={isSectionFormOpen}
                setIsSectionFormOpen={setIsSectionFormOpen}
                setFormAction={setFormAction}
                formAction={formAction}
                refetch={refetch}
              />
            </div>
          )}
        </div>


        {isSectionFormOpen && formAction === "create" && (
        <AdminSectionForm
          isSectionFormOpen={isSectionFormOpen}
          setIsSectionFormOpen={setIsSectionFormOpen}
          formAction={formAction}
          refetch={refetch}
        />
      )}
    
  </>
  )
}

export default SectionAdminPage