import React, { useState, useEffect } from "react";
import { capitalize } from "lodash";
import TableComp from "../MUITable/TableComp";
import { isAccessProvided } from "../../services/assetManagementService";
import {
  accessConstants,
  moduleConstants,
  subModuleConstants,
} from "../../constants/accessManagementConstants";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { nlp_date_key_const } from "../../constants/nplConstants";
import { dateFormate } from "../../utils/basicUtils";
import { db_keys_constants } from "../../constants/databaseKeyConstants";
import CancelIcon from "../../assets/images/adminPanel/CancelIcon.svg";
import { useDeleteExtendedAttribute } from "../../api/deleteApiFunctions";
import { useAtom } from "jotai";
import { authUser } from "../../atom/jotai";
import { toast } from "react-toastify";

/**
 * Component : AdminAttributeTable
 *
 * Description :This component is mainly for creating the format which is required by common table component for displaying mui table.
 * here we are creating the dynamic header and table row data of attributes.
 *
 * Props : attributeData - selected entity wise attibute data,
 *         accessDataByRole- if the logged in user has the access,
 *         selectedType - type of entity type,
 *         getAttributeDataRefetch - for refetching the attribute data
 *  */

const AdminAttributeTable = ({
  attributeData,
  accessDataByRole,
  selectedType,
  getAttributeDataRefetch,
}) => {
  const excludingList = [
    "name",
    "type",
    "created_by_id",
    "id",
    "in_use",
    "updated_at",
    "updated_by_id",
    "updated_by_name",
    "value",
  ];

  const mandatoryFieldsAtFirstPosition = ["name", "type"];

  //Global states
  const [user] = useAtom(authUser);

  //Local states
  //State for maintaining table headers info for common table component
  const [tableHeader, setTableHeader] = useState([]);
  //State for maintaining table row data info for common table component
  const [tableRowData, setTableRowData] = useState([]);
  // to show a confirmation dialog to delete a attribute
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deletingAttributeID, setDeletingAttributeID] = useState(null);
  const {
    mutate: deleteExtendedAttribute,
    data: extendedAttrDataForDelete,
    isSuccess: isSuccessForExtendedAttr,
    isError: isErrorForDeleteExtendedAttr,
    error: errorForDeleteExtendedAttr,
  } = useDeleteExtendedAttribute();

  //Below uesEffect() is used for when the attribute is deleted successfully
  useEffect(() => {
    if (isSuccessForExtendedAttr) {
      toast.success(extendedAttrDataForDelete?.message);
      getAttributeDataRefetch();
    }
  }, [extendedAttrDataForDelete]);

  //Below uesEffect() is used for showing an error while hitting the delete attribute api
  useEffect(() => {
    if (isErrorForDeleteExtendedAttr) {
      toast.error(errorForDeleteExtendedAttr?.response?.data?.error[0]);
    }
  }, [errorForDeleteExtendedAttr, isErrorForDeleteExtendedAttr]);

  useEffect(() => {
    if (attributeData) {
      let col_data = [];
      let col_data_row_wise = [];
      let row_data = [];
      attributeData.length > 0 &&
        attributeData.forEach((attrData) => {
          let row_data_object = {};

          Object.entries(attrData).forEach(([key, value], i) => {
            let col_data_object = {};
            if (mandatoryFieldsAtFirstPosition.includes(key)) {
              col_data_object = { field: key, label: capitalize(key) };

              //Adding column name as per row wise
              col_data_row_wise.push(col_data_object);

              // //adding row value as per column
              // row_data_object[key] =
              //   value === null || value === "" ? "-" : value;
            } else if (!excludingList.includes(key)) {
              col_data_object = {
                field: key,
                label: db_keys_constants.hasOwnProperty(`${key}`)
                  ? db_keys_constants[key]
                  : capitalize(key),
              };

              //Adding column name as per row wise
              col_data_row_wise.push(col_data_object);
            }

            //adding row value as per column and also the other values like id, etc.,
            row_data_object[key] = nlp_date_key_const.includes(`${key}`)
              ? dateFormate(value)
              : value;
          });

          //Adding column action at the last position of the column header
          if (
            isAccessProvided(
              moduleConstants.admin_view,
              subModuleConstants.extended_attribute,
              null,
              accessConstants.delete,
              accessDataByRole
            ) === true
          ) {
            col_data_row_wise.push({
              field: "actions",
              label: "",
              width: "5%",
            });
          }

          col_data.push(col_data_row_wise);
          col_data_row_wise = []; //making array blank to avoid duplication

          //Adding row data for action column in row header
          if (
            isAccessProvided(
              moduleConstants.admin_view,
              subModuleConstants.extended_attribute,
              null,
              accessConstants.delete,
              accessDataByRole
            ) === true
          ) {
            row_data_object.actions = ["delete"];
          }

          row_data.push(row_data_object); //clubbing single row to the whole bunch of data
        });

      if (col_data.length > 0) {
        setTableHeader(col_data[0]);
      }
      if (row_data.length > 0) {
        setTableRowData(row_data);
      }
    }
  }, [attributeData]);

  const handleDeleteAttribute = () => {
    const payload = {
      asset_type: selectedType,
      contributor_id: user?.contributor_id,
      id: deletingAttributeID,
    };
    deleteExtendedAttribute(payload);
    setDeletingAttributeID(null);
    setOpenDeleteDialog(false);
  };

  const handleActionClick = (rowData, actionType) => {
    if (actionType === "delete") {
      setDeletingAttributeID(rowData?.id);
      setOpenDeleteDialog(true);
    }
  };

  return (
    <div>
      {tableHeader.length > 0 && tableRowData.length > 0 ? (
        <TableComp
          id="adminAttributeTable"
          tableHeader={tableHeader}
          tableRowData={tableRowData}
          handleActionClick={handleActionClick}
        />
      ) : (
        <CircularProgress />
      )}

      <Dialog
        id="adminAttributeDeleteConfirmation"
        open={openDeleteDialog}
        onClose={() => {
          setOpenDeleteDialog(!openDeleteDialog);
        }}
        aria-labelledby="alert-attr-dialog-title"
        aria-describedby="alert-dattr-ialog-description"
        sx={{
          "& .MuiDialog-paper": {
            boxShadow: " 0px 4px 6.66667px 0px rgba(0, 0, 0, 0.30)",
            borderRadius: "8px",
            padding: "25px 0px",
          },
        }}
      >
        <DialogTitle id="alert-attr- dialog-title">
          <IconButton
            id="adminAttributeCloseDeleteDialog"
            aria-label="close"
            onClick={() => {
              setOpenDeleteDialog(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <img src={CancelIcon} alt="Delete" />
          </IconButton>
          <p className="delete-dialog-text">
            {" "}
            {"Are you sure you want to delete this attribute?"}{" "}
          </p>
        </DialogTitle>
        <DialogActions>
          <Button
            id="adminAttributeCancelDeleteDialog"
            className="cancel-deleteUser-dialog"
            value="Cancel"
            onClick={() => {
              setOpenDeleteDialog(false);
              setDeletingAttributeID(null);
            }}
            autoFocus
          >
            <span>Cancel</span>
          </Button>
          <Button
            id="adminAttributeConfirmDelete"
            className="delete-deleteUser-dialog"
            value="Delete"
            onClick={() => {
              handleDeleteAttribute();
            }}
          >
            <span>Delete</span>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AdminAttributeTable;
