import {
  accessConstants,
  actionConstants,
  moduleConstants,
  subModuleConstants,
} from "../constants/accessManagementConstants";
import { PolicyNameConst } from "../constants/policyConstants";
import { isAccessProvided } from "./assetManagementService";

export const checkAccessForSidebar = (sidebarData, accessDataByRole) => {
  let accessPresent = false;

  if (
    sidebarData.label === "Home" ||
    sidebarData.label === "Section" ||
    sidebarData.label === "Subsection"
  ) {
    accessPresent = true;
  } else if (sidebarData.label === "Assets") {
    accessPresent = isAccessProvided(
      moduleConstants.asset_view,
      subModuleConstants.crud,
      null,
      accessConstants.read,
      accessDataByRole
    );
  } else if (sidebarData.label === "Profile") {
    accessPresent = isAccessProvided(
      moduleConstants.profile_view,
      subModuleConstants.crud,
      null,
      accessConstants.read,
      accessDataByRole
    );
  } else if (sidebarData.label === "Discovery") {
    accessPresent = isAccessProvided(
      moduleConstants.extraction,
      subModuleConstants.crud,
      null,
      accessConstants.read,
      accessDataByRole
    );
  } else if (sidebarData.label === "Release Info") {
    accessPresent = true;
  } else if (sidebarData.label === "Users") {
    accessPresent = isAccessProvided(
      moduleConstants.admin_view,
      subModuleConstants.users,
      null,
      accessConstants.read,
      accessDataByRole
    );
  } else if (sidebarData.label === "Regions") {
    accessPresent = isAccessProvided(
      moduleConstants.admin_view,
      subModuleConstants.regions,
      null,
      accessConstants.read,
      accessDataByRole
    );
  } else if (sidebarData.label === "Business Units") {
    accessPresent = isAccessProvided(
      moduleConstants.admin_view,
      subModuleConstants.business_units,
      null,
      accessConstants.read,
      accessDataByRole
    );
  } else if (sidebarData.label === PolicyNameConst.policies) {
    accessPresent = isAccessProvided(
      moduleConstants.policy,
      subModuleConstants.policy_view,
      null,
      accessConstants.read,
      accessDataByRole
    );
  } else if (sidebarData.label === "Details Settings") {
    accessPresent = isAccessProvided(
      moduleConstants.admin_view,
      subModuleConstants.asset_metadata,
      actionConstants.modify,
      accessConstants.read,
      accessDataByRole
    );
  } else if (sidebarData.label === "Risk Factors") {
    accessPresent = isAccessProvided(
      moduleConstants.admin_view,
      subModuleConstants.risk_factors,
      actionConstants.visibility,
      accessConstants.read,
      accessDataByRole
    );
  } else if (sidebarData.label === "Business Unit Assignment") {
    accessPresent = isAccessProvided(
      moduleConstants.admin_view,
      subModuleConstants.business_units_assignment,
      null,
      accessConstants.read,
      accessDataByRole
    );
  } else if (sidebarData.label === "Card Templates") {
    accessPresent = isAccessProvided(
      moduleConstants.card_templates,
      subModuleConstants.crud,
      null,
      accessConstants.read,
      accessDataByRole
    );
  } else if (sidebarData.label === "Role Management") {
    accessPresent = isAccessProvided(
      moduleConstants.admin_view,
      subModuleConstants.role_management,
      null,
      accessConstants.read,
      accessDataByRole
    );
  } else if (sidebarData.label === "Organization Management") {
    accessPresent = isAccessProvided(
      moduleConstants.admin_view,
      subModuleConstants.org_logo,
      null,
      accessConstants.read,
      accessDataByRole
    );
  } else if (sidebarData.label === "Sections") {
    accessPresent = isAccessProvided(
      moduleConstants.admin_view,
      subModuleConstants.users,
      null,
      accessConstants.read,
      accessDataByRole
    );
  } else if (sidebarData.label === "Subsections") {
    accessPresent = isAccessProvided(
      moduleConstants.admin_view,
      subModuleConstants.users,
      null,
      accessConstants.read,
      accessDataByRole
    );
  } else if (sidebarData.label === "Extended attributes") {
    accessPresent = isAccessProvided(
      moduleConstants.admin_view,
      subModuleConstants.extended_attribute,
      null,
      accessConstants.read,
      accessDataByRole
    );
  }
  return accessPresent;
};
