import React, { useState, useEffect } from "react";
import {
  IconButton,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  TextField,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Select from "react-select";
import { useAtom } from "jotai";
import { toast } from "react-toastify";
import CancelIcon from "../../assets/images/adminPanel/CancelIcon.svg";
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import { authUser } from "../../atom/jotai";
import { useCreateAttribute } from "../../api/createApiFunctions";
import {
  AssetsNameConstant,
  databaseDatatypeConst,
} from "../../constants/nameConstants";

const optionsForAttrType = [
  {
    value: "boolean",
    label: databaseDatatypeConst.boolean,
  },
  {
    value: "string",
    label: databaseDatatypeConst.string,
  },
  {
    value: "number",
    label: databaseDatatypeConst.number,
  },
];

const optionsForAssetAttrType = [
  {
    value: "app",
    label: AssetsNameConstant.apps,
  },
  {
    value: "model",
    label: AssetsNameConstant.models,
  },
  {
    value: "input_data_table",
    label: AssetsNameConstant.datasets,
  },
];

/**
 * Component : AdminAttributeCrudDialog
 *
 * Description :This component is mainly for creating the attributes.
 *
 * Props : attributeData - selected entity wise attibute data,
 *         accessDataByRole- if the logged in user has the access,
 *         selectedType - type of entity type,
 *         getAttributeDataRefetch - for refetching the attribute data
 *  */

const AdminAttributeCrudDialog = ({
  openAttributeDialog,
  setOpenAttributeDialog,
  invokerType,
  getAttributeDataRefetch,
}) => {
  //Global states
  const [user] = useAtom(authUser);
  //Local States
  const [selectedAttrType, setSelectedAttrType] = useState(null);
  const [selectedAssetTypeOfAttr, setSelectedAssetTypeOfAttr] = useState(null);

  // form validation rules
  const validationSchema = Yup.object().shape({
    asset_type: Yup.string().required("Attribute asset type is required"),
    name: Yup.string().required("Attribute Name is required"),
    type: Yup.string().required("Attribute type is required"),
    description: Yup.string().optional().trim(),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  //User Form
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm(formOptions);

  const {
    mutate: createAttribute,
    data: attrDataAfterCreate,
    isSuccess: isSuccessAfterCreateAttr,
    isError: isErrorAfterCreateAttr,
    error: errorfterCreateAttr,
  } = useCreateAttribute();

  //Below uesEffect() is used for when the attribute is deleted successfully
  useEffect(() => {
    if (isSuccessAfterCreateAttr) {
      toast.success(attrDataAfterCreate?.message);
      getAttributeDataRefetch();
      setOpenAttributeDialog(false);
    }
  }, [attrDataAfterCreate]);

  //Below uesEffect() is used for showing an error while hitting the delete attribute api
  useEffect(() => {
    if (isErrorAfterCreateAttr) {
      if (
        errorfterCreateAttr?.response?.data?.error[0] ===
        "Attribute name is present"
      ) {
        setError("name", {
          type: "custom",
          message: `${errorfterCreateAttr?.response?.data?.error[0]}`,
        });
      } else {
        toast.error(errorfterCreateAttr?.response?.data?.error[0]);
        setOpenAttributeDialog(false);
      }
    }
  }, [errorfterCreateAttr]);

  const handleRegistration = (data) => {
    if (invokerType === "create") {
      const payload = {
        asset_type: data?.asset_type,
        contributor_id: user?.contributor_id,
        attribute_name: data?.name,
        attribute_type: data?.type,
        description: data.description,
      };
      createAttribute(payload);
    }
  };

  const handleCancel = () => {
    setOpenAttributeDialog(false);
  };

  const handleError = () => {};

  return (
    <>
      <Dialog
        open={openAttributeDialog}
        onClose={() => {
          setOpenAttributeDialog(!openAttributeDialog);
        }}
        aria-labelledby={`${invokerType} attribute dialog`}
        aria-describedby={`${invokerType} attribute dialog`}
        sx={{
          "& .MuiDialog-paper": {
            boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.3)",
            borderRadius: "8px",
            maxWidth: "800px",
            width: "675px",
            height: "415px",
          },
        }}
      >
        <DialogTitle
          id={`${invokerType} attribute dialog title`}
          sx={{ m: 0, p: 3 }}
        >
          <Typography variant="h5" component="div" className="create-heading">
            {invokerType === "create"
              ? "Create Extended Attribute"
              : "Edit Extended Attribute"}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => {
              setOpenAttributeDialog(false);
            }}
            sx={{
              position: "absolute",
              right: 16,
              top: 27,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <img src={CancelIcon} alt="Cancel" />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ position: "relative", p: 3 }}>
          <form
            onSubmit={handleSubmit(handleRegistration, handleError)}
            autoComplete="off"
            style={{ marginTop: "10px", height: "85%", overflowY: "auto" }}
          >
            <div
              className="display-row"
              id="attribute_assetType_row"
              style={{ gridTemplateColumns: "1fr 4fr" }}
            >
              <label className="display-label">
                Asset Type
                <span style={{ color: "#F25757" }}>{`*`}</span>
              </label>
              <Select
                id="attribute_assetType_dropdown"
                value={selectedAssetTypeOfAttr && selectedAssetTypeOfAttr}
                isClearable={false}
                // isDisabled={isDisabled}
                styles={{
                  container: (base) => ({
                    ...base,
                    focusVisible: {
                      outline: "none",
                    },
                  }),
                  control: (base) => ({
                    ...base,
                    marginTop: "-8px",
                    border: "none",
                    borderBottom: " 1px solid #6d8291",
                    borderRadius: "2px",
                  }),
                }}
                maxMenuHeight="100px"
                onChange={(event) => {
                  clearErrors("asset_type");
                  setValue("asset_type", event.value);
                  setSelectedAssetTypeOfAttr(event);
                }}
                options={optionsForAssetAttrType}
              />
            </div>
            {errors?.asset_type && errors.asset_type.message && (
              <div
                className="display-row-erros"
                id="attribute_assetType_error"
                style={{ gridTemplateColumns: "1fr 4fr" }}
              >
                <div></div>
                <small className="text-danger">
                  {errors?.asset_type && errors.asset_type.message}
                </small>
              </div>
            )}

            <div
              className="display-row"
              style={{ gridTemplateColumns: "1fr 4fr" }}
              id="createAttrName"
            >
              <label className="display-label">
                Name
                <span style={{ color: "#F25757" }}>{`*`}</span>
              </label>
              <TextField
                className="display-input"
                sx={{ "& .MuiInputBase-input": { padding: "0px" } }}
                id="attribute_name"
                // label="Name"
                variant="standard"
                {...register("name")}
              />
            </div>
            {errors?.name && errors.name.message && (
              <div
                className="display-row-erros"
                id="attribute_name_er"
                style={{ gridTemplateColumns: "1fr 4fr" }}
              >
                <div></div>
                <small className="text-danger">
                  {errors?.name && errors.name.message}
                </small>
              </div>
            )}

            <div
              className="display-row"
              id="attribute_type_row"
              style={{ gridTemplateColumns: "1fr 4fr" }}
            >
              <label className="display-label">
                Type
                <span style={{ color: "#F25757" }}>{`*`}</span>
              </label>
              <Select
                id="attribute_type_dropdown"
                value={selectedAttrType && selectedAttrType}
                isClearable={false}
                // isDisabled={isDisabled}
                styles={{
                  container: (base) => ({
                    ...base,
                    focusVisible: {
                      outline: "none",
                    },
                  }),
                  control: (base) => ({
                    ...base,
                    marginTop: "-8px",
                    border: "none",
                    borderBottom: " 1px solid #6d8291",
                    borderRadius: "2px",
                  }),
                }}
                maxMenuHeight="100px"
                onChange={(event) => {
                  clearErrors("type");
                  setValue("type", event.value);
                  setSelectedAttrType(event);
                }}
                options={optionsForAttrType}
              />
            </div>
            {errors?.type && errors.type.message && (
              <div
                className="display-row-erros"
                id="attribute_type_error"
                style={{ gridTemplateColumns: "1fr 4fr" }}
              >
                <div></div>
                <small className="text-danger">
                  {errors?.type && errors.type.message}
                </small>
              </div>
            )}

            <div
              className="display-row"
              style={{ gridTemplateColumns: "1fr 4fr" }}
              id="createAttrDesc"
            >
              <label className="display-label">Description</label>
              <TextField
                className="display-input"
                sx={{ "& .MuiInputBase-root": { padding: "0px" } }}
                id="attribute_description"
                maxRows={2}
                variant="standard"
                multiline
                {...register("description")}
              />
            </div>
            {errors?.description && errors.description.message && (
              <div
                className="display-row-erros"
                id="createAttributeDescErr"
                style={{ gridTemplateColumns: "1fr 4fr" }}
              >
                <div></div>
                <small className="text-danger">
                  {errors?.description && errors.description.message}
                </small>
              </div>
            )}
            {/* 
            {invokerType === "edit" &&
              Object.entries(editedAttributeRowdata).map(([key, val], i) => {
                return (
                  !mandatoryFields.includes(key) && (
                    <div className="display-row">
                      <label className="display-label">{key}</label>
                      <TextField
                        className="display-input"
                        sx={{ "& .MuiInputBase-input": { padding: "0px" } }}
                        id="attribute_name"
                        // label="Name"
                        variant="standard"
                        {...register("name")}
                      />
                    </div>
                  )
                );
              })} */}

            <div className="button-div_for_form">
              <ButtonComponent
                name={invokerType === "create" ? "Create" : "Update"}
                activeButton={true}
                handleActiveClick={handleSubmit(
                  handleRegistration,
                  handleError
                )} // Pass handleRegistration here
              />
            </div>
          </form>

          <div className="button-div">
            <ButtonComponent
              name={"Cancel"}
              passiveButton={true}
              handlePassiveClick={handleCancel}
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AdminAttributeCrudDialog;
