import React, { useEffect, useState } from "react";
import TableComp from "../MUITable/TableComp";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
} from "@mui/material";
import AdminSectionForm from "./AdminSectionForm";
import CancelIcon from "../../assets/images/adminPanel/CancelIcon.svg";
import { useDeleteSection } from "../../api/deleteApiFunctions";
import { toast } from "react-toastify";

const AdminSectionTable = ({
  sectionData,
  setFormAction,
  setIsSectionFormOpen,
  isSectionFormOpen,
  formAction,
  refetch,
}) => {
  //State for maintaining table headers info for common table component
  const [tableHeader, setTableHeader] = useState([]);
  //State for maintaining table row data info for common table component
  const [tableRowData, setTableRowData] = useState([]);

  const [selectedRowData, setSelectedRowData] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const {
    mutate: deleteSection,
    data: deleteSectionData,
    isSuccess,
    isError,
  } = useDeleteSection();

  useEffect(() => {
    if (isSuccess) {
      setDeleteDialog(false);
      refetch();
      if (deleteSectionData?.success === true) {
        toast.success(deleteSectionData.message);
      }
      if (deleteSectionData?.success === false) {
        toast.error(deleteSectionData.message);
      }
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    //Table header
    let table_Header = [
      { field: "title", label: "Title" },
      { field: "description", label: "Description", width: "50%" },
      { field: "inApp", label: "In App" },
      { field: "inModel", label: "In Model" },
      { field: "inInputDataTable", label: "In Dataset" },
    ];

    table_Header.push({ field: "actions", label: "", width: "10%" });

    setTableHeader(table_Header); //Table header state
  }, []);

  useEffect(() => {
    if (sectionData) {
      //Table Row data
      let table_rows_data = [];
      sectionData?.length > 0 &&
        sectionData?.forEach((sectionInfo) => {
          let row_data = {};
          row_data.title = sectionInfo.title;

          row_data.description =
            sectionInfo.description === "null" ? "" : sectionInfo.description;

          row_data.inApp = sectionInfo.inApp;
          row_data.inModel = sectionInfo.inModel;
          row_data.inInputDataTable = sectionInfo.inInputDataTable;
          row_data.section_id = sectionInfo.section_id;
          let row_data_action_array = [];

          row_data_action_array.push("edit");

          row_data_action_array.push("delete");

          row_data.actions = row_data_action_array;

          //Adding single row data to whole bunch
          table_rows_data.push(row_data);
        });
      setTableRowData(table_rows_data);
    }
  }, [sectionData]);

  const handleActionClick = (rowData, actionType) => {
    if (actionType === "edit") {
      setIsSectionFormOpen(true);
      setFormAction("edit");
      setSelectedRowData(rowData);
    }
    if (actionType === "delete") {
      setDeleteDialog(true);
      setSelectedRowData(rowData);
    }
  };
  return (
    <div>
      {tableHeader.length > 0 && tableRowData.length > 0 ? (
        <TableComp
          id="adminSectionTable"
          tableHeader={tableHeader}
          tableRowData={tableRowData}
          handleActionClick={handleActionClick}
        />
      ) : (
        <CircularProgress />
      )}

      {isSectionFormOpen && formAction === "edit" && (
        <AdminSectionForm
          isSectionFormOpen={isSectionFormOpen}
          setIsSectionFormOpen={setIsSectionFormOpen}
          formAction={formAction}
          selectedRowData={selectedRowData}
          refetch={refetch}
        />
      )}

      <Dialog
        id="buDeleteDialog"
        open={deleteDialog}
        onClose={() => {
          setDeleteDialog(!deleteDialog);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            boxShadow: " 0px 4px 6.66667px 0px rgba(0, 0, 0, 0.30)",
            borderRadius: "8px",
            padding: "25px 0px",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <IconButton
            id="buCloseDeleteDialog"
            aria-label="close"
            onClick={() => {
              setDeleteDialog(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <img src={CancelIcon} alt="Delete" />
          </IconButton>

          <p className="delete-dialog-text" style={{ width: "385px" }}>
            {" "}
            {"Are you sure you want to delete this section?"}{" "}
          </p>
        </DialogTitle>
        <DialogActions>
          <>
            <Button
              id="buCancelDeleteDialog"
              className="cancel-deleteUser-dialog"
              value="Cancel"
              onClick={() => {
                setDeleteDialog(false);
              }}
              autoFocus
            >
              <span>Cancel</span>
            </Button>
            <Button
              id="buConfrimDelete"
              className="delete-deleteUser-dialog"
              value="Delete"
              onClick={() => {
                deleteSection(selectedRowData.section_id);
              }}
            >
              <span>Delete</span>
            </Button>
          </>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AdminSectionTable;
