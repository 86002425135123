export const databaseDatatypeConst = {
  boolean: "Yes/No",
  string: "Text",
  number: "Number",
};

export const AssetsNameConstant = {
  apps: "Application",
  models: "Model",
  datasets: "Dataset",
};
