import axios from "axios";
import { getLocalTokens } from "../../utils/authUtils";

let authTokens = getLocalTokens();

const genAIApiInstance = axios.create({
  baseURL: `${process.env.REACT_APP_OPEN_AI_API}`,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${authTokens?.accessToken}`,
  },
});

// attach auth token to axios req cycle
genAIApiInstance.interceptors.request.use(
  (config) => {
    authTokens = getLocalTokens();
    config.headers["Authorization"] = "Bearer " + authTokens?.accessToken;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default genAIApiInstance