import React, { useEffect, useState } from "react";
import { useDeleteSubsection } from "../../api/deleteApiFunctions";
import { dateFormate } from "../../utils/basicUtils";
import TableComp from "../MUITable/TableComp";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
} from "@mui/material";
import AdminSubsectionForm from "./AdminSubsectionForm";
import CancelIcon from "../../assets/images/adminPanel/CancelIcon.svg";
import { toast } from "react-toastify";
import { useGetAllSection } from "../../api/readApiFunctions";

const AdminSubsectionTable = ({
  subSectionData,
  isSubsectionFormOpen,
  setIsSubsectionFormOpen,
  setFormAction,
  formAction,
  refetch,
}) => {
  //State for maintaining table headers info for common table component
  const [tableHeader, setTableHeader] = useState([]);
  //State for maintaining table row data info for common table component
  const [tableRowData, setTableRowData] = useState([]);

  const [selectedRowData, setSelectedRowData] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const {
    mutate: deleteSubsection,
    data: deleteSubsectionData,
    isSuccess,
    isError,
  } = useDeleteSubsection();
  const { data: sectionData } = useGetAllSection();

  useEffect(() => {
    if (isSuccess) {
      setDeleteDialog(false);
      refetch();
      if (deleteSubsectionData?.success === true) {
        toast.success(deleteSubsectionData.message);
      }
      if (deleteSubsectionData?.success === false) {
        toast.error(deleteSubsectionData.message);
      }
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    //Table header
    let table_Header = [
      { field: "title", label: "Title" },
      { field: "description", label: "Description", width: "40%" },
      { field: "section", label: "Section" },
      { field: "display_order", label: "Display Order" },
      { field: "version", label: "Version" },
      { field: "created_at", label: "Created At" },
    ];

    table_Header.push({ field: "actions", label: "", width: "10%" });

    setTableHeader(table_Header); //Table header state
  }, []);

  useEffect(() => {
    if (subSectionData && sectionData) {
      //Table Row data
      let table_rows_data = [];
      subSectionData?.length > 0 &&
        subSectionData?.forEach((subSectionInfo) => {
          let sectionName = "-";
          let row_data = {};
          row_data.title = subSectionInfo.title;

          row_data.description =
            subSectionInfo?.description?.length === 0 ||
            subSectionInfo?.description === "null"
              ? ""
              : subSectionInfo.description;

          row_data.section_id = subSectionInfo.section_id;
          row_data.created_at = subSectionInfo?.created_at
            ? dateFormate(subSectionInfo.created_at)
            : "-";
          row_data.display_order = subSectionInfo.display_order;
          row_data.version = subSectionInfo.version;
          row_data.subsection_id = subSectionInfo.subsection_id;
          sectionData.filter((section) => {
            if (section.section_id === subSectionInfo.section_id) {
              sectionName = section.title;
            }
          });
          row_data.section = sectionName;
          let row_data_action_array = [];

          row_data_action_array.push("edit");

          row_data_action_array.push("delete");

          row_data.actions = row_data_action_array;

          //Adding single row data to whole bunch
          table_rows_data.push(row_data);
        });
      setTableRowData(table_rows_data);
    }
  }, [subSectionData, sectionData]);

  const handleActionClick = (rowData, actionType) => {
    if (actionType === "edit") {
      setIsSubsectionFormOpen(true);
      setFormAction("edit");
      setSelectedRowData(rowData);
    }
    if (actionType === "delete") {
      setDeleteDialog(true);
      setSelectedRowData(rowData);
    }
  };

  return (
    <div>
      {tableHeader.length > 0 && tableRowData.length > 0 ? (
        <TableComp
          id="adminSectionTable"
          tableHeader={tableHeader}
          tableRowData={tableRowData}
          handleActionClick={handleActionClick}
        />
      ) : (
        <CircularProgress />
      )}

      {isSubsectionFormOpen && formAction === "edit" && (
        <AdminSubsectionForm
          isSubsectionFormOpen={isSubsectionFormOpen}
          setIsSubsectionFormOpen={setIsSubsectionFormOpen}
          formAction={formAction}
          selectedRowData={selectedRowData}
          refetch={refetch}
        />
      )}

      <Dialog
        id="buDeleteDialog"
        open={deleteDialog}
        onClose={() => {
          setDeleteDialog(!deleteDialog);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            boxShadow: " 0px 4px 6.66667px 0px rgba(0, 0, 0, 0.30)",
            borderRadius: "8px",
            padding: "25px 0px",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <IconButton
            id="buCloseDeleteDialog"
            aria-label="close"
            onClick={() => {
              setDeleteDialog(false);
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <img src={CancelIcon} alt="Delete" />
          </IconButton>

          <p className="delete-dialog-text" style={{ width: "385px" }}>
            {" "}
            {"Are you sure you want to delete this Subsection?"}{" "}
          </p>
        </DialogTitle>
        <DialogActions>
          <>
            <Button
              id="buCancelDeleteDialog"
              className="cancel-deleteUser-dialog"
              value="Cancel"
              onClick={() => {
                setDeleteDialog(false);
              }}
              autoFocus
            >
              <span>Cancel</span>
            </Button>
            <Button
              id="buConfrimDelete"
              className="delete-deleteUser-dialog"
              value="Delete"
              onClick={() => {
                deleteSubsection(selectedRowData.subsection_id);
              }}
            >
              <span>Delete</span>
            </Button>
          </>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AdminSubsectionTable;
