import {
  Accordion,
  AccordionDetails,
  Checkbox,
  Typography,
  AccordionSummary,
  styled,
  Dialog,
  DialogTitle,
  IconButton,
  Button,
  DialogActions,
  Popover,
} from "@mui/material";
import React, { useState } from "react";
import { PolicyNameConst } from "../../constants/policyConstants";
import { ArrowForwardIosSharp, Close } from "@mui/icons-material";

/**
 * Component : CardTemplateDeletQByPolicy
 *
 * Description : This component is responsible for showing policy accordion(list of policies). And while clicking/checking 
 * the policy we will get confirmation from user that you want to delete the questions as per the seleted policy and 
 * for confirmation dialod popup will appear.
 *
 * Props :      policyData - list of all policies available in database,
                showPolicyDropdown - state to save the status of policy dropdown,
                handleClosePolicyDropdown - handler function for closing the policy dropdown,
                handleDeleteQuestionsByPolicy - handler function for calling the delete api for deleting question as per policy,
 *  */

const AccordionSummaryDrop = styled((props) => (
  <AccordionSummary
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
}));

const CardTemplateDeletQByPolicy = ({
  policyData,
  showPolicyDropdown,
  handleClosePolicyDropdown,
  handleDeleteQuestionsByPolicy,
}) => {
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  // to show a confirmation dialog for deleting questions as per policy
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  return (
    <>
      {policyData && showPolicyDropdown && (
        <Popover
          sx={{
            "& .MuiPopover-paper": {
              boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
              borderRadius: "4px",
              border: "0.5px solid #6D8291 ",
              width: "380px",
              padding: "5px",
            },
          }}
          open={showPolicyDropdown}
          anchorEl={showPolicyDropdown}
          onClose={() => {
            handleClosePolicyDropdown();
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              sx={{
                marginLeft: "6px",
                fontFamily: "var(--fontFamilyText)",
                fontSize: "18px",
                fontWeight: 500,
              }}
            >
              Select {PolicyNameConst.policy} to delete questions
            </Typography>
            <Close
              id="deleteQByPolicyPopOverCancelDiv"
              fontSize="small"
              onClick={() => {
                handleClosePolicyDropdown();
              }}
            />
          </div>

          <div style={{ maxHeight: "300px", overflowY: "auto" }}>
            {policyData?.map((policy, index) => {
              return (
                <Typography
                  style={
                    selectedPolicy === policy.score_name
                      ? { backgroundColor: "#F2F7F9" }
                      : {}
                  }
                  sx={{
                    margin: "5px 0px 5px 8px",
                    fontFamily: "Lato",
                    fontWeight: 500,
                    fontSize: "16px",
                    color: "#666666",
                    cursor: "pointer",
                    media: {
                      small: {
                        fontSize: "13px",
                      },
                      large: {
                        fontSize: "13px",
                      },
                    },
                  }}
                  key={index}
                  id={`deleteQByPolicy${index}`}
                  onClick={() => {
                    setSelectedPolicy(policy.score_name);
                    setOpenDeleteDialog(true);
                  }}
                >
                  {policy.score_name}
                </Typography>
              );
            })}
          </div>
        </Popover>
      )}

      <Dialog
        id="cardTemplateDltConfirmQByPolicy"
        open={openDeleteDialog}
        onClose={(e, reason) => {
          if (reason && reason == "backdropClick") return;
          setOpenDeleteDialog(!openDeleteDialog);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiDialog-paper": {
            boxShadow: " 0px 4px 6.66667px 0px rgba(0, 0, 0, 0.30)",
            borderRadius: "8px",
            padding: "25px 0px",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">
          <IconButton
            id="cardTemplateDltConfirmQByPolicyClose"
            aria-label="close"
            onClick={() => {
              setOpenDeleteDialog(false);
              handleClosePolicyDropdown();
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
            {/* <img src={CancelIcon} alt="Delete" /> */}
          </IconButton>
          <p className="delete-dialog-text" style={{ width: "400px" }}>
            Are you sure you want to delete all questions tagged with "
            {selectedPolicy}" {PolicyNameConst.policy} ?
          </p>
        </DialogTitle>
        <DialogActions>
          <Button
            id="cardTemplateDltConfirmQByPolicyDeleteDialog"
            className="cancel-deleteUser-dialog"
            value="Cancel"
            onClick={() => {
              setOpenDeleteDialog(false);
              handleClosePolicyDropdown();
            }}
            autoFocus
          >
            <span>Cancel</span>
          </Button>
          <Button
            id="cardTemplateDltConfirmQByPolicyDelete"
            className="delete-deleteUser-dialog"
            value="Delete"
            onClick={() => {
              setOpenDeleteDialog(false);
              handleDeleteQuestionsByPolicy(selectedPolicy);
            }}
          >
            <span>Delete</span>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CardTemplateDeletQByPolicy;
