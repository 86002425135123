import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import CancelIcon from "../../assets/images/adminPanel/CancelIcon.svg";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useCreateSection } from "../../api/createApiFunctions";
import { useUpdateSectionData } from "../../api/updateApiFunctions";
import { toast } from "react-toastify";

const createSectionValidationSchema = Yup.object().shape({
  title: Yup.string().required("Section title is required").trim(),
  description: Yup.string().optional().trim().nullable(true),
  inApp: Yup.boolean().optional().default(false),
  inModel: Yup.boolean().optional().default(false),
  inInputDataTable: Yup.boolean().optional().default(false),
});

const AdminSectionForm = ({
  isSectionFormOpen,
  setIsSectionFormOpen,
  formAction,
  selectedRowData,
  refetch,
}) => {
  const {
    mutate: createSection,
    data: createSectionData,
    isSuccess: createSuccess,
  } = useCreateSection();
  const {
    mutate: updateSection,
    data: updateSectionData,
    isSuccess: updateSuccess,
  } = useUpdateSectionData();

  const formOptions = { resolver: yupResolver(createSectionValidationSchema) };

  //Section Form
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    setError,
  } = useForm(formOptions);

  const handleFormSubmit = (formSubmitData) => {
    if (formSubmitData.description.length > 500) {
      setError("description", {
        type: "custom",
        message: `Length should not exceed 500 characters`,
      });
    } else {
      if (formAction === "create") {
        createSection(formSubmitData);
      }

      if (formAction === "edit") {
        updateSection({
          ...formSubmitData,
          section_id: selectedRowData.section_id,
        });
      }
    }
  };

  useEffect(() => {
    if (formAction === "edit" && selectedRowData) {
      setValue("title", selectedRowData.title);
      setValue("description", selectedRowData.description);
      setValue("inApp", selectedRowData.inApp);
      setValue("inModel", selectedRowData.inModel);
      setValue("inInputDataTable", selectedRowData.inInputDataTable);
    }
  }, [formAction]);

  useEffect(() => {
    if (createSuccess) {
      setIsSectionFormOpen(false);
      refetch();
      if (createSectionData?.success === true) {
        toast.success(createSectionData.message);
      }
      if (createSectionData?.success === false) {
        toast.error(createSectionData.message);
      }
    }

    if (updateSuccess) {
      setIsSectionFormOpen(false);
      refetch();
      if (updateSectionData?.success === true) {
        toast.success(updateSectionData.message);
      }
      if (updateSectionData?.success === false) {
        toast.error(updateSectionData.message);
      }
    }
  }, [createSectionData, updateSectionData]);

  return (
    <>
      <Dialog
        id="createBusinessunitDialog"
        open={isSectionFormOpen}
        onClose={() => {
          setIsSectionFormOpen(!isSectionFormOpen);
        }}
        aria-labelledby="new-user-dialog"
        aria-describedby="new-user-dialog"
        sx={{
          "& .MuiDialog-paper": {
            boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.3)",
            borderRadius: "8px",
            width: "675.4px",
            height: "470.96px",
          },
        }}
      >
        <DialogTitle id="new-user-dialog" sx={{ m: 0, p: 3 }}>
          <Typography variant="h5" component="div" className="create-heading">
            {formAction === "create" ? "Create New Section" : "Edit Section"}
          </Typography>
          <IconButton
            id="closeBusinessunitDialog"
            aria-label="close"
            onClick={() => {
              setIsSectionFormOpen(false);
            }}
            sx={{
              position: "absolute",
              right: 16,
              top: 27,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <img src={CancelIcon} alt="Delete" />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ position: "relative", p: 3 }}>
          <form
            id="createBusinessunitform"
            onSubmit={handleSubmit(handleFormSubmit)}
            autoComplete="off"
            style={{ marginTop: "10px" }}
          >
            <div
              className="form_inputs"
              style={{ height: "300px", overflowY: "auto" }}
            >
              <div className="info-row" id="formSectionTitle">
                <label className="info-label">Title*</label>
                <TextField
                  className="info-input"
                  sx={{ "& .MuiInputBase-input": { padding: "0px" } }}
                  id="section_title"
                  variant="standard"
                  {...register("title")}
                />
              </div>
              {errors?.title && errors.title.message && (
                <div className="info-row-erros" id="formSectionTitleErr">
                  <div></div>
                  <small className="text-danger">
                    {errors?.title && errors.title.message}
                  </small>
                </div>
              )}

              <div className="info-row" id="formSectionDescription">
                <label className="user-info-label">Description</label>
                <TextField
                  className="info-input"
                  sx={{ "& .MuiInputBase-root": { padding: "0px" } }}
                  id="section_description"
                  variant="standard"
                  multiline
                  {...register("description")}
                />
              </div>
              {errors?.description && errors.description.message && (
                <div className="info-row-erros" id="formSectionDescriptionErr">
                  <div></div>
                  <small className="text-danger">
                    {errors?.description && errors.description.message}
                  </small>
                </div>
              )}

              <div className="info-row" id="formSectionApp">
                <label className="user-info-label">In App</label>
                <FormControl component="app">
                  <Controller
                    control={control}
                    name="inApp"
                    defaultValue={false}
                    render={({ field }) => (
                      <RadioGroup row {...field}>
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="True"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="False"
                        />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </div>
              {errors?.inApp && errors.inApp.message && (
                <div className="info-row-erros" id="formSectionAppErr">
                  <div></div>
                  <small className="text-danger">
                    {errors?.inApp && errors.inApp.message}
                  </small>
                </div>
              )}

              <div className="info-row" id="formSectionModel">
                <label className="user-info-label">In Model</label>
                <FormControl component="model">
                  <Controller
                    control={control}
                    name="inModel"
                    defaultValue={false}
                    render={({ field }) => (
                      <RadioGroup row {...field}>
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="True"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="False"
                        />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </div>
              {errors?.inModel && errors.inModel.message && (
                <div className="info-row-erros" id="formSectionModelErr">
                  <div></div>
                  <small className="text-danger">
                    {errors?.inModel && errors.inModel.message}
                  </small>
                </div>
              )}

              <div className="info-row" id="formSectionDataset">
                <label className="user-info-label">In Dataset</label>
                <FormControl component="dataset">
                  <Controller
                    control={control}
                    name="inInputDataTable"
                    defaultValue={false}
                    render={({ field }) => (
                      <RadioGroup row {...field}>
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="True"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="False"
                        />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </div>
              {errors?.inInputDataTable && errors.inInputDataTable.message && (
                <div className="info-row-erros" id="formSectionDatasetErr">
                  <div></div>
                  <small className="text-danger">
                    {errors?.inInputDataTable &&
                      errors.inInputDataTable.message}
                  </small>
                </div>
              )}
            </div>

            <button className="create-button" id="createBusineeunitButton">
              <span>{formAction === "create" ? "Create" : "Update"}</span>
            </button>
            <button
              className="cancel-button"
              id="buCancelButton"
              onClick={() => {
                setIsSectionFormOpen(false);
              }}
            >
              <span>Cancel</span>
            </button>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AdminSectionForm;
