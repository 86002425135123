import { Close } from "@mui/icons-material";
import { IconButton, Popover, Typography } from "@mui/material";
import React from "react";
import { sampleQueries } from "../../constants/sampleQueryConstant";

/**
 * Component : SampleQuerySearch
 *
 * Description : This component is reponsible for showing the sample queries popover
 *
 * Props : positionOfSampleQueriesPopover - For opening the popover near the sample query button,
 *         open - store the boolean value that represents the status of popover whether it is open/close,
 *         handleSampleQueryPopoverClose - handle click function for closing the popup,
 *         handleSampleQueryClick - handle click function when the paricular query is clicked,
 */

const SampleQuerySearch = ({
  positionOfSampleQueriesPopover,
  open,
  handleSampleQueryPopoverClose,
  handleSampleQueryClick,
}) => {
  return (
    <div>
      {open && (
        <div
          style={{
            position: "absolute",
            top:
              positionOfSampleQueriesPopover?.getBoundingClientRect().top + 25,
            left:
              positionOfSampleQueriesPopover?.getBoundingClientRect().left + 25,
          }}
        >
          <div className="triangle_up"></div>
          <Popover
            sx={{
              "& .MuiPopover-paper": {
                boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
                borderRadius: "4px",
                width: "600px",
                paddingBottom: "5px",
                marginTop: "10px",
                marginLeft: "-120px",
              },
            }}
            id="SampleQueriesPopover"
            open={open}
            anchorEl={positionOfSampleQueriesPopover}
            onClose={handleSampleQueryPopoverClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <div className="popover-arrow" />
            <div style={{ display: "flex", justifyContent: "end" }}>
              <IconButton
                id="sampleQueryPopOverCancelDiv"
                onClick={handleSampleQueryPopoverClose}
                sx={{ padding: "0px" }}
              >
                <Close fontSize="small" style={{ color: "#000" }} />
              </IconButton>
            </div>
            <div style={{ maxHeight: "200px", overflowY: "auto" }}>
              {sampleQueries.map((query, index) => {
                return (
                  <Typography
                    sx={{
                      margin: "5px 0px 5px 12px",
                      padding: "2px",
                      fontFamily: "Lato",
                      fontWeight: 500,
                      fontSize: "15px",
                      color: "#666666",
                      cursor: "pointer",
                      media: {
                        small: {
                          fontSize: "13px",
                        },
                        large: {
                          fontSize: "13px",
                        },
                      },
                    }}
                    key={`SampleQuery${index}`}
                    id={`SampleQuery${index}`}
                    onClick={() => {
                      handleSampleQueryClick(query);
                    }}
                  >
                    {query}
                  </Typography>
                );
              })}
            </div>
          </Popover>
        </div>
      )}
    </div>
  );
};

export default SampleQuerySearch;
