import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CancelIcon from "../../assets/images/adminPanel/CancelIcon.svg";
import { useGetAllSection } from "../../api/readApiFunctions";
import { useCreateSubsection } from "../../api/createApiFunctions";
import { useUpdateSubsectionData } from "../../api/updateApiFunctions";
import { toast } from "react-toastify";

const createSubsectionValidationSchema = Yup.object().shape({
  title: Yup.string().required("Subsection title is required").trim(),
  description: Yup.string().optional().trim().nullable(true),
  version: Yup.number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable(),
  section_id: Yup.number("Invalid section")
    .required("Section is required")
    .nullable(true),
  display_order: Yup.number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable(),
});

const AdminSubsectionForm = ({
  isSubsectionFormOpen,
  setIsSubsectionFormOpen,
  formAction,
  selectedRowData,
  refetch,
}) => {
  const [selectedSection, setSelectedSection] = useState(null);

  const { data: sectionData } = useGetAllSection();
  const {
    mutate: createSubsection,
    data: createSubsectionData,
    isSuccess: createSubsectionSuccess,
  } = useCreateSubsection();
  const {
    mutate: updateSubsection,
    data: updateSubsectionData,
    isSuccess: updateSubsectionSuccess,
  } = useUpdateSubsectionData();

  const formOptions = {
    resolver: yupResolver(createSubsectionValidationSchema),
  };

  //Section Form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
  } = useForm(formOptions);

  const handleFormSubmit = (formSubmitData) => {
    if (formSubmitData.description.length > 500) {
      setError("description", {
        type: "custom",
        message: `Length should not exceed 500 characters`,
      });
    } else {
      if (formAction === "create") {
        createSubsection(formSubmitData);
      }

      if (formAction === "edit") {
        updateSubsection({
          ...formSubmitData,
          subsection_id: selectedRowData.subsection_id,
        });
      }
    }
  };

  useEffect(() => {
    if (formAction === "edit" && selectedRowData) {
      setValue("title", selectedRowData.title);
      setValue("description", selectedRowData.description);
      setValue("version", selectedRowData.version);
      setValue("section_id", selectedRowData.section_id);
      setValue("display_order", selectedRowData.display_order);
      setSelectedSection(selectedRowData.section_id);
    }
  }, [formAction]);

  useEffect(() => {
    if (createSubsectionSuccess) {
      setIsSubsectionFormOpen(false);
      refetch();
      if (createSubsectionData?.success === true) {
        toast.success(createSubsectionData.message);
      }
      if (createSubsectionData?.success === false) {
        toast.error(createSubsectionData.message);
      }
    }

    if (updateSubsectionSuccess) {
      setIsSubsectionFormOpen(false);
      refetch();
      if (updateSubsectionData?.success === true) {
        toast.success(updateSubsectionData.message);
      }
      if (updateSubsectionData?.success === false) {
        toast.error(updateSubsectionData.message);
      }
    }
  }, [createSubsectionData, updateSubsectionData]);

  return (
    <>
      <Dialog
        id="createBusinessunitDialog"
        open={isSubsectionFormOpen}
        onClose={() => {
          setIsSubsectionFormOpen(!isSubsectionFormOpen);
        }}
        aria-labelledby="new-user-dialog"
        aria-describedby="new-user-dialog"
        sx={{
          "& .MuiDialog-paper": {
            boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.3)",
            borderRadius: "8px",
            width: "675.4px",
            height: "470.96px",
          },
        }}
      >
        <DialogTitle id="new-user-dialog" sx={{ m: 0, p: 3 }}>
          <Typography variant="h5" component="div" className="create-heading">
            {formAction === "create"
              ? "Create New Subsection"
              : "Edit Subsection"}
          </Typography>
          <IconButton
            id="closeBusinessunitDialog"
            aria-label="close"
            onClick={() => {
              setIsSubsectionFormOpen(false);
            }}
            sx={{
              position: "absolute",
              right: 16,
              top: 27,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <img src={CancelIcon} alt="Delete" />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ position: "relative", p: 3 }}>
          <form
            id="createBusinessunitform"
            onSubmit={handleSubmit(handleFormSubmit)}
            autoComplete="off"
            style={{ marginTop: "10px" }}
          >
            <div style={{ height: "300px", overflowY: "auto" }}>
              <div className="info-row" id="formSectionTitle">
                <label className="info-label">Title*</label>
                <TextField
                  className="info-input"
                  sx={{ "& .MuiInputBase-input": { padding: "0px" } }}
                  id="section_title"
                  variant="standard"
                  {...register("title")}
                />
              </div>
              {errors?.title && errors.title.message && (
                <div className="info-row-erros" id="formSectionTitleErr">
                  <div></div>
                  <small className="text-danger">
                    {errors?.title && errors.title.message}
                  </small>
                </div>
              )}

              <div className="info-row" id="formSectionDescription">
                <label className="user-info-label">Description</label>
                <TextField
                  className="info-input"
                  sx={{ "& .MuiInputBase-root": { padding: "0px" } }}
                  id="section_description"
                  variant="standard"
                  multiline
                  {...register("description")}
                />
              </div>
              {errors?.description && errors.description.message && (
                <div className="info-row-erros" id="formSectionDescriptionErr">
                  <div></div>
                  <small className="text-danger">
                    {errors?.description && errors.description.message}
                  </small>
                </div>
              )}

              {formAction === "edit" && (
                <div className="info-row" id="form_subsection_display_order">
                  <label className="user-info-label">Display Order</label>
                  <TextField
                    className="info-input"
                    sx={{ "& .MuiInputBase-root": { padding: "0px" } }}
                    id="section_display_order"
                    variant="standard"
                    multiline
                    {...register("display_order")}
                  />
                </div>
              )}
              {errors?.display_order && errors.display_order.message && (
                <div
                  className="info-row-erros"
                  id="form_subsection_display_order_err"
                >
                  <div></div>
                  <small className="text-danger">
                    {errors?.display_order && errors.display_order.message}
                  </small>
                </div>
              )}

              <div className="info-row" id="form_subsection_version">
                <label className="user-info-label">Version</label>
                <TextField
                  className="info-input"
                  sx={{ "& .MuiInputBase-root": { padding: "0px" } }}
                  id="section_version"
                  variant="standard"
                  multiline
                  {...register("version")}
                />
              </div>
              {errors?.version && errors.version.message && (
                <div
                  className="info-row-erros"
                  id="form_subsection_version_err"
                >
                  <div></div>
                  <small className="text-danger">
                    {errors?.version && errors.version.message}
                  </small>
                </div>
              )}

              <div className="info-row" id="form_subsection_version">
                <label className="user-info-label">Section</label>
                <Select
                  id="createUser-role"
                  value={selectedSection}
                  isClearable={false}
                  styles={{
                    container: (base) => ({
                      ...base,
                      focusVisible: {
                        outline: "none",
                      },
                    }),
                    control: (base) => ({
                      ...base,
                      marginTop: "-8px",
                      border: "none",
                      borderBottom: " 1px solid #6d8291",
                      borderRadius: "2px",
                    }),
                  }}
                  maxMenuHeight="100px"
                  onChange={(event) => {
                    if (formAction === "create") {
                      setValue("section_id", event.target.value);
                      setSelectedSection(event.target.value);
                    }
                  }}
                >
                  {sectionData &&
                    sectionData?.map((section) => {
                      return (
                        <MenuItem value={section.section_id}>
                          {section.title}
                        </MenuItem>
                      );
                    })}
                </Select>
              </div>
              {errors?.section_id && errors.section_id.message && (
                <div
                  className="info-row-erros"
                  id="form_subsection_version_err"
                >
                  <div></div>
                  <small className="text-danger">
                    {errors?.section_id && errors.section_id.message}
                  </small>
                </div>
              )}
            </div>

            <button className="create-button" id="createBusineeunitButton">
              <span>{formAction === "create" ? "Create" : "Update"}</span>
            </button>
            <button
              className="cancel-button"
              id="buCancelButton"
              onClick={() => {
                setIsSubsectionFormOpen(false);
              }}
            >
              <span>Cancel</span>
            </button>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AdminSubsectionForm;
