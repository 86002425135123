export const sampleQueries = [
    "show all apps which violate regulation fairness",
    "list all models which are in violation of regulation privacy",
    "what are all the unused models?",
    "show me the unused datasets",
    "find the apps with annotations that mention Debu",
    "show the models and regulation names and their scores",
    "show least answered questions and their total, answered and unanswered counts",
  ];
  
  
